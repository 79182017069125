import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../components/breadcrumb'
import FragrancesList from '../components/fragrances-list'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import Rating from '../components/rating'
import SEO from '../components/seo'


export const query = graphql`
  query CountryTemplateQuery(
    $countryId: String!,
    $genderId: PostGraphile_Gender!,
    $imageMain: String!,
  ) {
    ...SiteInfo
    imageMain: file(relativePath: { eq: $imageMain }) { ...ImageMain }
    results: postgres {
      country: countryById(id: $countryId) {
        ...Country
        brands: brandsList(
          filter: { genders: { contains: [ $genderId ] }},
        ) {
          ...Brand
        }
        fragrances: fragrancesList(filter: { gender: { equalTo: $genderId }}) {
          ...Fragrance
        }
        notes: notesList(
          genderId: $genderId
          first: 5
        ) {
          ...Note
        }
      }
    }
  }
`

export default ({ data }) => {
  const { links, meta } = data.site.siteMetadata
  const { entity } = meta
  const { country } = data.results
  const { brands, fragrances, notes } = country
  const imageMain = data.imageMain.childImageSharp.fixed

  const page = country.name
  const parent = 'Countries'
  const title = `${page} - ${entity.singular} ${parent}`
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: parent,
      slug: links.countries,
    },
    {
      name: page,
      slug: country.slug,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description: country.about,
    image: imageMain.src,
    keywords,
    name: title,
    place: country,
    slug: country.slug,
  }

  return (
    <Layout>
      <SEO
        description={country.about}
        keywords={keywords}
        schema={schema}
        title={title}
      />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>

      <Image
        alt={country.name}
        credit={country.image}
        fixed={imageMain}
      />

      {country.about && (
        <Paragraph>
          {country.about}
        </Paragraph>
      )}

      <Paragraph>
        Language: {country.language}
      </Paragraph>

      <Heading level={2}>{country.language} {entity.plural}</Heading>
      <Rating count={country.ratings} rating={country.rating} />
      <br/>
      <FragrancesList fragrances={fragrances} />

      <Heading level={2}>{country.language} Brands</Heading>
      <List>
        {brands.map(brand => (
          <ListItem key={brand.id}>
            <Link href={brand.slug}>
              {brand.name}
            </Link>
          </ListItem>
        ))}
      </List>

      <Heading level={2}>
        {country.language} Notes
      </Heading>
      <List>
        {notes.map(note => (
          <ListItem key={note.id}>
            <Link href={note.slug}>
              {note.name}
            </Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
